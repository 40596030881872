import { css } from "styled-components";
import { breakpoint } from "./helpers/breakpoints";

export default {
  AddNote: {
    style: css`
      && {
        font-size: 40px;
        position: relative;
        top: 5px;
        cursor: pointer;
      }
    `
  },
  AssetDetails: {
    AssetDetailsWrapper: {
      style: css`
        @media print {
          padding: 20px;

          & .hide_print {
            display: none;
          }

          & .avoid-page-break {
            page-break-inside: avoid;
          }
        }
      `
    },
    ButtonsWrapper: {
      style: css`
        margin-top: 10px;
        padding-left: 7.5px;

        & > button {
          margin-right: 15px;
          margin-bottom: 15px;
        }

        & > a {
          margin-bottom: 15px;
        }

        @media ${breakpoint.sm} {
          display: grid;
          grid-template-columns: auto auto auto;
          float: right;
          margin-top: 0px;
        }

        @media ${breakpoint.m} {
          display: grid;
          grid-template-columns: auto auto auto;
          float: right;
          margin-top: 15px;
        }
      `
    },
    AssetDetailsMetricsWrapper: {
      style: css`
        padding: 7px;

        @media ${breakpoint.sm} {
          padding: 32px;
        }
      `
    },
    AssetImage: {
      style: css`
        max-width: 150px;
        max-height: 200px;
        padding-left: 60px;
        position: relative;
        top: -25px;
      `
    },
    ModelAssetImage: {
      style: css`
        width: 70%;
        height: 100%;
        position: relative;
      `
    },
    CapacityInformation: {
      style: css`
        @media print {
          padding-top: 15px;
          page-break-inside: avoid;
          padding-bottom: 20px;
        }
      `
    },
    DetailsGrid: {
      style: css`
        display: -ms-grid
        display: grid;
        // -ms-grid-columns: 1fr;
        // grid-template-columns: 1fr;
        grid-gap: 32px;
        margin-top: 6px;
       // margin-bottom: 40px;
        padding-top: 2px;

        // @media ${breakpoint.sm} {
        //   -ms-grid-columns: 18% 2fr 1fr 1fr;
        //   grid-template-columns: 18% 2fr 1fr 1fr;
        // }

        // @media ${breakpoint.l} {
        //   -ms-grid-columns: 10% 2fr 1fr 1fr;
        //   grid-template-columns: 10% 2fr 1fr 1fr;
        // }

        @media print {
          -ms-grid-columns: auto auto auto auto;
          grid-template-columns: auto auto auto auto;
        }
      `
    },
    GraphsLineHCPS: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        grid-column-gap: 32px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          grid-template-areas:
            "graph1 graph2"
            "graph3 graph3";

          & > div:nth-of-type(1) {
            grid-area: graph1;
          }
          & > div:nth-of-type(2) {
            grid-area: graph2;
          }
          & > div:nth-of-type(3) {
            grid-area: graph3;
          }
        }

        @media ${breakpoint.l} {
          -ms-grid-columns: 1fr 3fr;
          grid-template-columns: 1fr 2fr;
        }
        @media ${breakpoint.mMax} {
          grid-row-gap: 20px;
        }

        && {
          @media print {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas:
              "graph1 graph2"
              "graph3 graph3";

            & > div:nth-of-type(1) {
              grid-area: graph1;
            }
            & > div:nth-of-type(2) {
              grid-area: graph2;
            }
            & > div:nth-of-type(3) {
              grid-area: graph3;
            }
          }
        }

        & > div {
          background-color: white;
          height: 100%;
        }
      `
    },

    GraphsLayout: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [2];
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 32px;
        }

        @media ${breakpoint.l} {
          -ms-grid-columns: (1fr) [2];
          grid-template-columns: repeat(2, 1fr);
        }
      `
    },
    GraphsLayoutForHCP: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 20px;
      `
    },

    GraphsLine1: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [1];
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 32px;
          // display: block;
        }

        @media ${breakpoint.mMax} {
          -ms-grid-columns: (1fr) [2];
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 32px;
        }

        @media ${breakpoint.l} {
          // -ms-grid-columns: 1fr 1fr
          // grid-template-columns: 1fr 1fr
          display: grid;
          grid-template-columns: calc(50% - 0px) calc(50% - 0px);
          grid-gap: 32px;
          width: calc(100% - 32px);
        }
      `
    },
    GraphsLine3: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        grid-gap: 20px;

        @media ${breakpoint.m} {
          // -ms-grid-columns: 1fr 1fr;
          // grid-template-columns: 1fr 1fr;
          // grid-template-rows: auto;
          // grid-template-areas:
          //   "graph1 graph2"
          //   "graph3 graph3";

          // & > div:nth-of-type(1) {
          //   grid-area: graph1;
          // }
          // & > div:nth-of-type(2) {
          //   grid-area: graph2;
          // }
          // & > div:nth-of-type(3) {
          //   grid-area: graph3;
          // }
          -ms-grid-columns: 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: "graph1 graph2 graph3";
        }

        @media ${breakpoint.l} {
          -ms-grid-columns: 1fr 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: "graph1 graph2 graph3";
          grid-gap: 32px;
        }

        && {
          @media print {
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas:
              "graph1 graph2"
              "graph3 graph3";

            & > div:nth-of-type(1) {
              grid-area: graph1;
            }
            & > div:nth-of-type(2) {
              grid-area: graph2;
            }
            & > div:nth-of-type(3) {
              grid-area: graph3;
            }
          }
        }

        & > div {
          background-color: white;
          height: 100%;
          // padding: 20px;
        }
      `
    },
    GraphsLine2: {
      style: css`
        @media print {
          margin: 0px;

          & canvas {
            max-width: 100%;
            max-height: 350px;
          }
        }
      `
    },
    GraphsGridContainer: {
      style: css`
      display:-ms-grid
      display: grid;
      -ms-grid-columns: 100%;
      grid-template-columns: 100%;
     // grid-gap: 20px;
      margin-top: 20px;

      @media ${breakpoint.sm} {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;   
        grid-template-rows: auto;    
      }

      @media ${breakpoint.m} {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;   
        grid-template-rows: auto;    
      }

      @media ${breakpoint.l} {
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
      }

      & > div {
        background-color: white;
       // min-height: 300px;
        //padding: 15px;

        @media ${breakpoint.sm} {
         // padding: 20px;
        }
      }

      @media print {
        display: block;
        position: relative;

        & canvas {
          max-width: 100%;
          margin: auto;
        }

        & > div {
          position: relative;
          padding-top: 20px;
          page-break-inside: avoid;
        }
      }
    `
    },
    GraphsGrid: {
      style: css`
        display:-ms-grid
        display: grid;
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        // grid-gap: 20px;
        // margin-top: 20px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: 100%;
          grid-template-columns: 100%;   
          grid-template-rows: auto;   
        }

        @media ${breakpoint.m} {
          -ms-grid-columns: 100%;
          grid-template-columns: 100%;   
          grid-template-rows: auto;  
        }

        @media ${breakpoint.l} {
          // -ms-grid-columns: auto auto;
          // grid-template-columns: 1fr 1fr;
          // grid-gap: 20px;
          // margin-top: 20px; 
          display: grid;
         grid-template-columns: calc(50% - 0px) calc(50% - 0px); /* Adjusted for the gap */
        grid-gap: 32px;
         margin-top: 32px;
         width: calc(100% - 32px);
        }

        & > div {
          background-color: white;
          min-height: 300px;
          //padding: 15px;
          margin-top: 32px; 

          @media ${breakpoint.sm} {
           // padding: 20px;
          }

          @media ${breakpoint.l}{
            margin: 0;
          }
        }

        @media print {
          display: block;
          position: relative;

          & canvas {
            max-width: 100%;
            margin: auto;
          }

          & > div {
            position: relative;
            padding-top: 20px;
            page-break-inside: avoid;
          }
        }
      `
    },
    GridLine1: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: (auto) [2];
        grid-template-columns: repeat(2, auto);
        grid-gap: 25px 32px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          justify-content: space-between;
        }
        @media print {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
        }

        @media (max-width: 600px) {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 2fr;
        }
        @media (max-width: 400px) {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr;
        }
        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [3];
          grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width: 960px) {
          grid-template-rows: 1fr 1fr;
        }
      `
    },
    GridLine12: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 25px 32px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          justify-content: space-between;
        }
        @media print {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
        }

        @media (max-width: 600px) {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 2fr;
        }
        @media ${breakpoint.sm} {
          -ms-grid-columns: 1fr 1fr 1fr;
          //grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr 1fr;
          //margin-top: 20px;
        }
        @media ${breakpoint.l} {
          -ms-grid-columns: 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          margin-top: 0;
        }
      `
    },
    GridLine3: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: (auto) [2];
        grid-template-columns: repeat(2, auto);
        grid-gap: 15px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          justify-content: space-between;
        }
        @media print {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
        }

        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [4];
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 600px) {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 2fr;
        }
        @media (max-width: 800px) {
          -ms-grid-columns: (1fr) [4];
          grid-template-columns: repeat(3, 1fr);
        }
      `
    },

    GridLine11: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
        grid-gap: 25px 32px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          justify-content: space-between;
        }
        @media print {
          -ms-grid-columns: 1fr;
          grid-template-columns: 1fr;
          grid-gap: 30px;
        }

        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [3];
          grid-template-columns: repeat(3, 1fr);
          margin-top: 20px;
        }

        @media ${breakpoint.m} {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 1fr 1fr;
          margin-top: 0;
        }
        @media ${breakpoint.l} {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 1fr;
          margin-top: 0;
        }
        @media (max-width: 600px) {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: 1fr 1fr;
          margin-top: 20px;
        }
        @media (min-width: 960px) {
          grid-template-rows: 1fr 1fr;
        }
      `
    },
    GridLine13: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
        grid-gap: 25px 32px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          justify-content: space-between;
        }
        // @media print {
        //   -ms-grid-columns: 1fr;
        //   grid-template-columns: 1fr;
        //   grid-gap: 30px;
        // }

        @media ${breakpoint.sm} {
          -ms-grid-columns: (1fr) [3];
          grid-template-columns: repeat(3, 1fr);
          margin-top: 20px;
        }

        // @media ${breakpoint.m} {
        //   -ms-grid-columns: (auto) [2];
        //   grid-template-columns: repeat(5, 1fr);
        //   margin-top: 0;
        // }
        @media ${breakpoint.l} {
          -ms-grid-columns: (auto) [2];
          grid-template-columns: repeat(5, 1fr);
          margin-top: 0;
        }
        // @media (max-width: 600px) {
        //   -ms-grid-columns: (auto) [2];
        //   grid-template-columns: 1fr 1fr;
        //   margin-top: 20px;
        // }
        // @media (min-width: 960px) {
        //   grid-template-rows: 1fr 1fr;
        // }
      `
    },
    GridMaxMin: {
      style: css`
        display:-ms-grid
        display: grid;
        grid-template-columns: 8fr 3fr;
        -ms-grid-columns: 8fr 1fr;
        grid-gap: 15px;
      `
    },
    GridButtons: {
      style: css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
      `
    },
    GridLine2: {
      style: css`
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 80px;
        grid-row-gap: 30px;
        padding-top: 35px;

        @media print {
          margin-top: 50px;
          grid-template-columns: 1fr;
          grid-gap: 80px;
        }

        @media ${breakpoint.m} {
          grid-template-columns: repeat(2, auto);
        }
      `
    },
    GridLine5: {
      style: css`
        display: -ms-flexbox;
        display: grid;
        -ms-grid-columns: (auto) [2];
        grid-template-columns: 1fr;
        background-color: white;
        // max-height: 470px;
        // grid-gap: 20px;

        @media ${breakpoint.l} {
          -ms-grid-columns: (1fr) [3];
          // grid-template-columns: 2fr 2fr 4fr;
          grid-template-columns: 28% 36% 36%;
        }
        @media ${breakpoint.minlarge} {
          -ms-grid-columns: (1fr) [3];
          // grid-template-columns: 2fr 2fr 4fr;
          grid-template-columns: 24% 38% 38%;
        }
        & > div {
          border-right: 1px solid #dcdddd;
          padding-right: 0;
          @media ${breakpoint.l} {
            padding-right: 20px;
          }
        }
        & > div:last-chile {
          border-right: 0;
        }
      `
    },
    IconText: {
      style: css`
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;

        & > svg {
          fill: ${p => p.color};
        }

        & span {
          color: ${p => (p.colorText ? p.color : null)};
        }
      `
    },
    InformationGrid: {
      style: css`
        display: grid;
        border-top: 1px solid black;
        padding-top: 15px;
        grid-template-columns: repeat(2, auto);
        margin-top: 6px;
        grid-row-gap: 15px;

        @media ${breakpoint.sm} {
          grid-template-columns: repeat(4, auto);
        }
      `
    },
    UpgradesGrid: {
      style: css`
        display: grid;
        border-top: 1px solid black;
        padding-top: 15px;
        grid-template-columns: repeat(2, auto);
        margin-top: 6px;
      `
    }
  },
  AssetReportBackground: {
    style: css`
      padding: 0xp 100px;
      background-color: grey;
    `
  },
  AssetReportGrid: {
    style: css`
      display: grid;
      grid-template-columns: 1fr auto;
      height: calc(100vh - 198px);

      @media ${breakpoint.sm} {
        height: calc(100vh - 216px);
      }
    `
  },
  AssetReportWrapper: {
    style: css`
      height: 100%;
      overflow: hidden;
      position: relative;
    `
  },
  BreadCrumb: {
    style: css`
      margin-left: 25px;
      padding: 10px 0px 10px 0px;

      @media ${breakpoint.sm} {
        padding: 10px 0px 20px 0px;
      }
    `
  },
  ButtonsWrapper: {
    style: css`
      display: grid;
      grid-template-columns: ${p => (p.escalated ? "auto" : "auto auto")};
      grid-column-gap: 15px;
      align-items: center;
      max-width: 200px;
      margin-left: auto;
      white-space: nowrap;

      svg {
        font-size: 30px;
      }

      @media ${breakpoint.sm} {
        max-width: unset;
      }
    `
  },
  Card: {
    AssetCardWrapper: {
      style: css`
        background-color: white;
        height: 100%;
        position: relative;
        // padding-bottom: 55px;
      `
    },
    AssetFooter: {
      style: css`
        border-top: 1px solid #ccced0;
        text-align: right;
        padding: 12px 16px 2px 8px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
      `
    },
    AssetHeader: {
      style: css`
        display: grid;
        grid-template-columns: 1fr auto;
        grid-row-gap: 5px;
        align-items: center;
        // padding: 25px 25px 5px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 3px;
        }
      `
    },
    AssetInfoLine1: {
      style: css`
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        align-items: baseline;
        padding: 20px 0px 25px;
        border-top: 2px solid lightgrey;
        margin: 0px 25px;
        height: 95px;

        span {
          display: flex;
          margin-top: 4px;
        }
      `
    },
    AssetInfoLine2: {
      style: css`
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr auto;
        grid-gap: 20px;
        align-items: baseline;
        padding: 6px 16px 12px 16px;

        span {
          display: flex;
          margin-bottom: 4px;
          align-items: flex-end;
        }
      `
    },
    AssetInfoLine3: {
      style: css`
        display: grid;
        display: -ms-grid;
        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr auto;
        grid-gap: 20px;
        align-items: baseline;
        padding: 0px 16px 16px 16px;
        span {
          display: flex;
          margin-bottom: 4px;
          align-items: flex-end;
        }
      `
    },
    AssetSubHeader: {
      style: css`
        display: grid;
        grid-template-columns: auto auto 1fr;
        padding: 0px 25px 0px;
        align-items: center;
        grid-gap: 10px;

        & > div {
          padding-left: 10px;
          border-left: 1px solid lightgrey;
        }
      `
    },
    ProgressWrapper: {
      style: css`
        // padding: 0px 16px 24px 16px;

        & > div {
          display: grid;
          grid-template-columns: 1fr auto;
        }
      `
    },
    TextField: {
      style: css`
        input {
          color: #414141;
          font-size: 22px;
          line-height: 30px;
          font-weight: 600;
          letter-spacing: 0.02em;
        }
      `
    }
  },
  CreateVolumeCard: {
    CreateVolumeCardWrapper: {
      style: css`
        background-color: white;
        height: 100%;
        position: relative;
        box-shadow: 0 0.3rem 2rem rgb(0 0 0 / 10%);
        border-radius: 4px;
      `
    },
    AssetHeader: {
      style: css`
        display: grid;
        grid-template-columns: 1fr auto;
        grid-row-gap: 5px;
        align-items: center;
        padding: 10px 20px 10px;

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 3px;
        }
      `
    },
    TextField: {
      style: css`
        input {
          color: #414141;
          font-size: 22px;
          line-height: 30px;
          font-weight: 600;
          letter-spacing: 0.02em;
        }
      `
    }
  },
  CloseDrawer: {
    style: css`
      height: 26px;
      width: 26px;
      background-color: #136bd2;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      left: 16px;
      top: 20px;

      & svg g path {
        fill: white;
      }
    `
  },
  Comments: {
    ButtonWrapper: {
      style: css`
        text-align: right;
        & button {
          font-size: 14px;
        }
      `
    },
    ChatS: {
      style: css`
        margin-right: 5px;
        position: relative;
        top: 2px;
      `
    },
    CommentsHeader: {
      style: css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
      `
    },
    CommentsInfo: {
      style: css`
        margin-left: 7px;
      `
    },
    CommentsTitle: {
      style: css`
        && {
          font-size: 26px;
        }
      `
    },
    CommentsWrapper: {
      style: css`
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid lightgrey;
      `
    },
    CommentsDisplay: {
      style: css`
        padding: 0px 15px;
        padding-bottom: 200vh;
        overflow: auto;
        height: 100%;
        scroll-behavior: smooth;
      `
    },
    CommentWrapper: {
      style: css`
        display: grid;
        grid-template-columns: ${p => (p.isCurrentUser ? "95% 5%" : "5% 95%")};
        margin-bottom: 10px;
      `
    },
    CommentDisplay: {
      style: css`
        background-color: ${p => (p.isCurrentUser ? "#DFECD1" : "#D3E3F6")};
        padding: 15px;
        border-radius: ${p =>
          p.isCurrentUser ? "15px 15px 15px 0px" : "15px 15px 0px 15px"};

        & div {
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `
    },
    InputWrapper: {
      style: css`
        background-color: #f0f0f0;
        padding: 15px;
        position: relative;
      `
    }
  },
  DropRightXS: {
    style: css`
      position: relative;
      left: -2px;
      top: -2px;
    `
  },
  EmptyAssetReport: {
    style: css`
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      height: calc(100% - 87px);

      &.customer-view {
        // width: calc(100% - 20%);
        width: 100%;
        // margin: 0 auto;
        background-color: white;
        height: calc(100vh - 150px);
        min-height: 180px;
      }
    `
  },
  EmptyMessageWrapper: {
    style: css`
      display: grid;
      grid-template-rows: auto auto 1fr;
      justify-items: center;
      align-items: center;
      color: grey;
      font-size: 22px;
      position: relative;
      top: 75px;
      text-align: center;
      padding: 0px 50px;

      svg {
        font-size: 100px;
        margin-top: 40px;
        color: lightgrey;
      }
    `
  },
  EscalationDetails: {
    style: css`
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 10px;
      align-items: center;
      color: ${p => p.color};
      font-weight: 600;
      cursor: pointer;

      svg > g g g path {
        fill: ${p => p.color};
      }

      svg > g g rect {
        fill: ${p => p.color};
      }
    `
  },
  ExpirationWrapper: {
    style: css`
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
    `
  },
  IconWrapper: {
    style: css`
      display: grid;
      place-items: center;
      align-items: center;
      padding: 2px 5px 2px 5px;
      cursor: pointer;
      box-align: center;
      border: ${p => (p.selected ? "2px solid #8b8b8b" : "1px solid #BCBCBC")};
      background: ${p => (p.selected ? "white" : "#F9F9F9")};
    `
  },
  IconsWrapper: {
    style: css`
      display: grid;
      display: -ms-grid;
      -ms-grid-columns: 40px 40px;
      grid-template-columns: 40px 40px;
      float: right;
    `
  },
  GroupHeaderWrapper: {
    style: css`
      display: grid;
      grid-template-columns: auto 1fr auto auto;
      grid-column-gap: 5px;
      margin-bottom: 5px;
      align-items: center;
    `
  },
  ReportIconWrapper: {
    style: css`
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      span {
        position: relative;
        top: -10px;
      }
    `
  },
  ReportWrapper: {
    style: css`
      padding: 0px 0px 50px;
      // overflow: auto;
      transition: padding 1s;
      position: absolute;
      right: 0px;
      width: ${p => (p.hasEscalations ? "calc(100vw - 20px)" : "100%")};
      height: 100%;

      @media ${breakpoint.sm} {
        padding: 0px 20px 80px;
        width: ${p => (p.hasEscalations ? "calc(100vw - 50px)" : "100%")};
      }

      @media ${breakpoint.l} {
        padding: ${p => (p.drawerOpen ? "0px 70px" : "0px 100px")};
        position: relative;
        width: unset;
        height: 85%;
      }

      @media print {
        padding: 0px;
        overflow: visible;

        &&& * {
          overflow: visible;
        }

        & .hide_print {
          display: none;
        }

        && > div {
          padding-top: 0px;
          padding-bottom: 0px;
          box-shadow: none;
        }
      }

      & > div {
        padding: 15px 15px 20px;
        height: calc(100vh - 196px);
        overflow: auto;
        background-color: white;
        position: relative;
        @media ${breakpoint.mw} {
          height: calc(100vh - 200px);
        }
        @media ${breakpoint.sm} {
          padding: 32px 48px 32px;
        }

        @media ${breakpoint.l} {
          padding: 32px 48px 32px;
        }
      }
    `
  },
  HeaderWrapper: {
    style: css`
      display: grid;
      grid-template-columns: 1fr;
      align-items: start;

      @media ${breakpoint.sm} {
        grid-template-columns: auto 1fr auto;
      }
    `
  },
  ReportTitle: {
    style: css`
      font-size: 16px;
      font-weight: 600;
      color: grey;
      // letter-spacing: 1.68px;
      line-height: 21px;
    `
  },
  EscalatedReportsWrapper: {
    style: css`
      display: flex;
      flex-direction: column;
      overflow: hidden;
      height: calc(100vh - 86px);
      border-left: 1px solid lightgrey;
    `
  },
  EscalatedReportsTable: {
    style: css`
      padding: 15px;
      overflow: auto;
      height: 100%;
    `
  },
  EscalatedTableRow: {
    style: css`
      &&:hover td {
        cursor: pointer;
      }

      && {
        height: unset;
      }

      & > td:nth-of-type(4) {
        text-align: center;
      }

      &&.selected td {
        border-bottom: none;
      }
    `
  },
  EscalatedTableRowDetails: {
    style: css`
      &&& td {
        cursor: pointer;
        white-space: normal;
        padding-top: 0px;
      }

      && {
        height: unset;
      }
    `
  },
  EscalatedTableCell: {
    style: css`
      ${p => p?.theme?.Table?.TableCell?.style}

      &&& {
        white-space: nowrap;
        border-bottom: 1px solid lightgrey;
        background-color: transparent;
        padding: 5px 10px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
    `
  },
  DrawerWrapper: {
    style: css`
      height: 100%;
      width: ${p => {
        if (p.hasEscalations) {
          return p.drawerOpen ? "102vw" : "50px";
        } else {
          return "0px";
        }
      }};
      overflow: hidden;
      margin-left: -30px;
      padding-left: 30px;
      position: relative;
      transition: width 1s;

      @media ${breakpoint.sm} {
      width: ${p => {
        if (p.hasEscalations) {
          return p.drawerOpen ? "450px" : "80px";
        } else {
          return "0px";
        }
      }};
    `
  },
  EscalatedReportsHeader: {
    style: css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 5px;
      align-items: center;
      white-space: nowrap;
      margin-top: 7px;

      & svg {
        font-size: 50px;
        margin-left: 3px;
        cursor: pointer;
        position: relative;
        top: 5px;

        &:nth-of-type(2) {
          margin-right: 3px;
          font-size: 45px;
        }
      }
    `
  },
  SerialLink: {
    style: css`
      color: #2c9bf0;
    `
  },
  EscalationComment: {
    style: css`
      white-space: pre-wrap;
      margin: 0;
    `
  },
  FilterWrapper: {
    style: css`
      display: grid;
      margin-top: 8px;
      padding: 0px 15px;
      grid-row-gap: 8px;

      & span {
        white-space: nowrap;
      }
    `
  },
  Modal: {
    Modal: {
      style: css`
        .MuiDialog-paper {
          padding: 25px;
          min-width: 400px;
        }
      `
    },
    ModalContent: {
      style: css`
        && {
          margin-left: 0px;
        }
      `
    },
    ModalTitle: {
      style: css`
        & > div > div {
          padding-left: 35px;
        }
      `
    },
    ReportIcon: {
      style: css`
        && {
          font-size: 45px;
          left: 8px;
          top: 22px;
        }
      `
    }
  },
  Popover: {
    Popover: {
      style: css`
        .MuiPaper-root {
          width: 350px;
        }
      `
    },
    PopoverTitle: {
      style: css`
        padding: 15px;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 2px solid #ececec;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;

        span {
          display: grid;
          align-items: center;
          cursor: pointer;
        }
      `
    },
    PopoverContent: {
      style: css`
        padding: 15px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 12px;

        &:nth-of-type(3) {
          background-color: #f8f8f8;
        }
      `
    }
  },
  SiteLocation: {
    style: css`
      font-size: 14px;
      color: grey;
      margin-bottom: 20px;
      line-height: 21px;
      // letter-spacing: 1.68px;
    `
  },
  SearchGrid: {
    style: css`
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-column-gap: 15px;
      padding: 15px 25px 15px 15px;
      width: 100%;

      @media ${breakpoint.sm} {
        grid-template-columns: auto 300px;
        grid-column-gap: 20px;
      }

      && > span {
        font-weight: 600;
        white-space: nowrap;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
    `
  },
  SearchWrapper: {
    style: css`
      display: grid;
      grid-template-columns: 0px 1fr 0px;
      align-items: center;
      background-color: white;
      padding: 0px;
      justify-items: flex-end;
      border-bottom: 1px solid lightgrey;

      @media ${breakpoint.sm} {
        grid-template-columns: 1fr auto 1fr auto;
      }
    `
  },
  StatusDot: {
    style: css`
      && {
        fill: ${p => p.color};
      }
    `
  },
  TableHeader: {
    style: css`
      ${p => p?.theme?.TableHeader?.style}
      &&& {
        background-color: #fafafa;
        padding: 10px 10px;
        span {
          font-size: 12px;
        }
      }
    `
  },
  TableCell: {
    style: css`
      ${p => p.theme.Table.TableCell.style}
      && {
        padding: 10px 15px;
        font-size: 14px;

        // border-bottom: none;
      }
      &:last-child {
        text-align: right;
      }
    `
  },
  TableCellForSpan: {
    style: css`
      ${p => p.theme?.Table?.TableCell?.style}
      && {
        padding: 10px 15px;
        // border-bottom: none;
      }
    `
  },
  TableBody: {
    style: css`
      & > tr {
        border-bottom: 1px solid lightgrey;
      }
    `
  },
  TableRow: {
    style: css`
      && {
        height: unset;
      }

      & th:last-child {
        text-align: right;
      }
    `
  },
  Table: {
    style: css`
      ${p => p.theme?.TableHeader?.style}
      @media (max-width: 600px) {
        display: block !important;
        overflow: auto;
      }
    `
  },
  DriveDataWrapper: {
    style: css`
      padding: 10px 10px;

      @media (min-width: 1270px) and (max-width: 1340px) {
        width: 100%;
        word-break: break-word;
      }

      &:first-child {
        justify-self: start;
        display: flex;
        align-items: flex-start;
      }
    `
  },
  DriveDataWrapperForDrives: {
    style: css`
      padding: 10px 10px;
      @media (min-width: 1270px) and (max-width: 1340px) {
        width: 100%;
        word-break: break-word;
      }

      &:first-child {
        justify-self: start;
        display: flex;
        align-items: center;
      }
    `
  },
  PortLayout: {
    style: css`
      display: grid;
      gap: 30px;
      // @media ${breakpoint.m} {
      //   display: flex;
      //   gap: 30px;
      // }
      @media ${breakpoint.mMax} {
        display: flex;
        flex-wrap:wrap;
        gap: 24px;
      }
      @media ${breakpoint.l} {
        display: flex;
        gap: 24px;
      }
    `
  },
  PortLayoutForGraph: {
    style: css`
      // padding: 5px;
      background-color: rgb(251, 252, 252);
      width: 100%;
      display: -ms-grid;
      display: flex;
      flex-direction: column;
      gap: 2px;
      //-ms-grid-columns: 1fr;
      //padding: 16px 16px 16px 10px;
      grid-template-columns: repeat(1, 1fr);
      // grid-gap: 32px;
      & > div {
        width: 100%;
        canvas {
          max-width: 100%;
        }
      }
      @media ${breakpoint.sm} {
        & > div {
          //padding: 0px 16px 16px 10px;
          width: 100%;
        }
      }
      @media ${breakpoint.m} {
        -ms-grid-columns: (1fr) [2];
        // grid-template-columns: repeat(2, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
      }
      @media ${breakpoint.l} {
        -ms-grid-columns: (1fr) [2];
        //width:310%
        max-width: 70%
        grid-template-columns: repeat(1, 1fr);
        & > div {
          width: 100%;
          margin-left: 0;
        }
    `
  },
  PortLayoutForTable: {
    style: css`
      //padding: 16px 16px 16px 10px;
      background-color: rgb(251, 252, 252);
      width: 100%;
      //display: -ms-grid;
      display: flex;
      flex-direction:column
      
      // -ms-grid-columns: 1fr;
      // grid-template-columns: repeat(1, 1fr);
      // grid-gap: 32px;
      // & > div {
      //   width: 100%;
      //   canvas {
      //     max-width: 100%;
      //   }
      // }
      // @media ${breakpoint.sm} {
      //   & > div {
      //     padding: 19px;
      //     width: 100%;
      //   }
      // }
      // @media ${breakpoint.m} {
      //   -ms-grid-columns: (1fr) [2];
      //   grid-template-rows: repeat(2, 1fr);
      //   & > div {
      //     width: 100%;
      //     margin-left: 0;
      //   }
      // }
      @media ${breakpoint.l} {
        max-width: 30%
        }
    `
  },

  NodeWrapper: {
    style: css`
      display: block;
      background:white
      margin:30px 0px;
      padding:1rem;
      // @media ${breakpoint.m} {
      //   display: flex;
      //   gap: 30px;
      // }
      // @media ${breakpoint.l} {
      //   display: flex;
      //   gap: 0;
      // }
    `
  }
};
